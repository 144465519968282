import React from "react";

const About = () => {
  return (
    <section
      className="py-24 flex flex-col sm:flex-row-reverse gap-6"
      id="om-oss"
    >
      <div className="w-full sm:w-1/2 text-center sm:text-left sm:my-auto">
        <h3 className="text-primary text-xl">OM OSS</h3>
        <h2 className="text-white text-3xl sm:text-4xl font-pixeled mb-4 leading-[2.5] sm:leading-loose">
          HVEM ER VI
        </h2>
        <p className="mb-6">
          Med flere års erfaring i å bygge høyytelses-PCer og tilby ekspert
          reparasjoner, er vi her for å hjelpe deg med å få mest mulig ut av
          ditt oppsett. Enten du er en gamer, skaper eller forretningsperson,
          sørger vi for at PCen din yter sitt beste.
        </p>
        <div className="flex flex-row text-left">
          <div className="w-1/2 flex flex-col gap-y-2">
            <p className="text-primary text-2xl">03+</p>
            <p>Års erfaring</p>
          </div>
          <div className="w-1/2 flex flex-col gap-y-2">
            <p className="text-primary text-2xl">400+</p>
            <p>Tilpassede PC bygg</p>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-1/2 sm:my-auto">
        <img
          src="https://pc-service.no/assets/contact.png"
          alt="PC-Service spesialister hjelper en kunde"
          className="border-2 border-primary lg:max-w-[30rem] mx-auto"
          loading="lazy"
        />
      </div>

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          name: "PC Service",
          description:
            "Eksperter i skreddersydde PC-bygg og reparasjoner. Over 3 års erfaring og 400+ PC-er bygget.",
          address: {
            "@type": "PostalAddress",
            addressLocality: "Fredrikstad",
            addressCountry: "NO",
          },
          image: "https://pc-service.no/assets/contact.png",
          areaServed: "Norway",
          foundingDate: "2025",
          telephone: "+47 950 48 706",
        })}
      </script>
    </section>
  );
};

export default About;
