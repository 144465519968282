import React from "react";
import Button from "./Universal/Button";

const Hero = ({ scrollToSection }) => {
  return (
    <section className="w-full flex flex-col md:flex-row justify-between py-12">
      <div className="w-full md:w-1/2 my-auto">
        <div className="flex flex-col">
          <h2 className="text-xl md:text-3xl">GJØR ARBEIDET DITT</h2>
          <h1 className="font-pixeled text-[1.5rem] md:text-[2.5rem] leading-relaxed">
            RASKERE, SMARTERE OG
            <span className="text-primary"> BEDRE</span>
            <img
              src="assets/logo_cursor_green.svg"
              alt="PC Service Fredrikstad grønn markør-logo"
              className="inline-block h-12 align-bottom ml-6 my-auto"
              loading="lazy"
            />
          </h1>
          <p className="mt-8 mb-8">
            Skreddersydde PC-er, ekspertreparasjoner og ferdige systemer med høy
            ytelse - designet for kraft, presisjon og pålitelighet.
          </p>
          <Button
            text="Kontakt oss nå"
            type="primary"
            classNames=" py-6 px-12"
            ariaLabel="Kontakt oss nå - PC Service Fredrikstad"
            onClick={() => scrollToSection("kontakt")}
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 p-2 mt-6 md:mt-0 lg:p-16">
        <img
          src="/assets/heroimg.png"
          alt="Høyytelses gaming-PC med RGB-belysning"
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default Hero;
