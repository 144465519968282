import React from "react";

const Button = ({ text, type, classNames, onClick, ariaLabel }) => {
  let typeClass = "";

  switch (type) {
    case "primary":
      typeClass = "border-image-button-green text-black";
      break;
    case "secondary":
      typeClass = "border-image-button-black text-primary";
      break;
    case "gray":
      typeClass = "border-image-button-gray text-white";
      break;
    default:
      typeClass = "border-image-button-black text-primary";
      break;
  }

  return (
    <button
      className={`${typeClass} ${classNames} px-10 py-5 transition-all hover:-translate-y-1 w-fit font-semibold`}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {text}
    </button>
  );
};

export default Button;
