import React from "react";
import { reviews } from "../CONSTANTS/constants";

const Reviews = () => {
  return (
    <section className="py-24" id="anmeldelser">
      <div className="flex flex-col gap-y-4 text-center items-center">
        <h3 className="text-primary text-xl">ANMELDELSER</h3>
        <h2 className="text-white text-3xl sm:text-4xl font-pixeled mb-4 leading-[2.5] sm:leading-loose">
          HVA VÅRE KUNDER SIER
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full mb-4">
          {reviews.map((review) => (
            <article
              key={review.id}
              className="border-image-button-gray p-8 flex flex-col text-left gap-y-4"
              itemScope
              itemType="https://schema.org/Review"
            >
              <div className="flex flex-row gap-6">
                <img
                  src={review.image}
                  alt={`Profilbilde av ${review.name}`}
                  className="h-16 rounded-full border-primary border-2"
                  loading="lazy"
                  itemProp="image"
                />
                <h3 className="text-2xl text-primary my-auto">{review.name}</h3>
              </div>
              <p className="mb-8">{review.review}</p>
              <time
                className="text-muted"
                dateTime={review.dato}
                itemProp="datePublished"
              >
                {review.dato}
              </time>
            </article>
          ))}
        </div>
      </div>

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Product",
          name: "PC Service",
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: "4.8",
            reviewCount: reviews.length,
          },
          review: reviews.map(({ name, review, dato }) => ({
            "@type": "Review",
            author: { "@type": "Person", name: name },
            reviewBody: review,
            datePublished: dato,
          })),
        })}
      </script>
    </section>
  );
};

export default Reviews;
