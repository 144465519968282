import { Sparkle } from "lucide-react";
import React from "react";

const Slider = () => {
  return (
    <div className="w-full bg-slider border-2 border-t-slider border-b-slider border-l-0 border-r-0">
      <div className="slider-wrapper max-w-full overflow-hidden">
        <div className="slider-marquee whitespace-nowrap overflow-hidden inline-flex py-6">
          <div className="flex flex-row gap-x-8 font-bold">
            <p>FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
            <p>FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
            <p>FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
            <p>FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
            <p>FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
          </div>
          <div className="flex flex-row gap-x-8 font-bold">
            <p className="ml-8">FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
            <p>FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
            <p>FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
            <p>FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
            <p>FERDIGE SYSTEMER</p>
            <Sparkle />
            <p>EKSPERT REPARASJON</p>
            <Sparkle />
            <p>SPESIALBYGDE PC-ER</p>
            <Sparkle />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
