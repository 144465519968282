import React from "react";

const Services = ({ scrollToSection, setTjeneste }) => {
  const handleClick = (tjeneste, sectionId) => {
    setTjeneste(tjeneste);
    scrollToSection(sectionId);
  };

  return (
    <section className="py-24" id="tjenester">
      <h2 className="text-center font-pixeled text-3xl leading-relaxed mb-20">
        TILPASSEDE PCER OG EKSPERT REPARASJON
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 w-full max-w-[1000px] mx-auto gap-6 text-black">
        <button
          className="service-card flex flex-col gap-y-4 relative cursor-pointer"
          onClick={() => handleClick("Skreddersydd PC", "kontakt")}
          aria-label="Bestill en skreddersydd PC"
        >
          <div className="p-4 lg:p-8 lg:w-1/2 text-left">
            <h3 className="text-lg font-semibold">Tilpassede PCer</h3>
            <p>
              Skreddersydd etter dine behov for gaming, jobb eller kreativitet
            </p>
          </div>
          <img
            src="https://pc-service.no/assets/service1.png"
            alt="Skreddersydd gaming-PC"
            className="-mt-20 max-h-80 lg:absolute lg:bottom-0 lg:right-0 lg:h-[calc(100%+100px)]"
            loading="lazy"
          />
        </button>

        <button
          className="service-card flex flex-col gap-y-4 relative cursor-pointer"
          onClick={() => handleClick("Kjøp PC", "kontakt")}
          aria-label="Kjøp ferdigbygde systemer"
        >
          <div className="p-4 lg:p-8 lg:w-1/2 text-left">
            <h3 className="text-lg font-semibold">Ferdigbygde systemer</h3>
            <p>Gode PCer som er klare til bruk, til gode priser</p>
          </div>
          <img
            src="https://pc-service.no/assets/service2.png"
            alt="Ferdigbygde datamaskiner klare til bruk"
            className="-mt-4 px-8 max-w-80 mx-auto lg:absolute lg:bottom-0 lg:right-0 lg:h-[calc(100%+40px)]"
            loading="lazy"
          />
        </button>

        <button
          className="service-card flex flex-col md:col-span-2 gap-y-4 relative cursor-pointer"
          onClick={() => handleClick("PC reparasjon", "kontakt")}
          aria-label="Bestill PC reparasjon"
        >
          <div className="p-4 lg:mb-60 text-left">
            <h3 className="text-lg font-semibold">PC reparasjon</h3>
            <p>Rask og pålitelig reparasjon for alle problemer</p>
          </div>
          <img
            src="https://pc-service.no/assets/service3bg.png"
            alt="Bakgrunn for PC reparasjonstjenester"
            className="-mt-4 hidden lg:block lg:absolute lg:bottom-0 lg:right-0 opacity-50"
            loading="lazy"
          />
          <img
            src="https://pc-service.no/assets/service3.png"
            alt="Reparasjon av datamaskin og laptoper"
            className="-mt-4 lg:absolute lg:bottom-0 lg:right-0 h-[calc(100%-0px)]"
            loading="lazy"
          />
        </button>
      </div>
    </section>
  );
};

export default Services;
