import React, { useState } from "react";
import Button from "./Universal/Button";

const Contact = ({ tjeneste, setTjeneste }) => {
  const [navn, setNavn] = useState("");
  const [epost, setEpost] = useState("");
  const [melding, setMelding] = useState("");

  const [formError, setFormError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // const WEBHOOK_URL = "https://pellenilsen.app.n8n.cloud/webhook-test/a5c0b23b-acbf-4fc2-8dd1-728f6065f1f7";
  const WEBHOOK_URL =
    "https://pellenilsen.app.n8n.cloud/webhook/a5c0b23b-acbf-4fc2-8dd1-728f6065f1f7";

  const handleSendMelding = async () => {
    setFormError("");
    setSuccessMessage("");

    if (!navn || !epost || !melding || !tjeneste) {
      setFormError("Vennligst fyll ut alle felter før du sender meldingen.");
      return;
    }

    try {
      const response = await fetch(WEBHOOK_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          navn,
          epost,
          tjeneste,
          melding,
        }),
      });

      if (response.ok) {
        setSuccessMessage("Meldingen ble sendt!");
        setNavn("");
        setEpost("");
        setTjeneste("Skreddersydd PC");
        setMelding("");
      } else {
        setFormError(
          "Noe gikk galt ved sendingen. Vennligst prøv igjen senere."
        );
      }
    } catch (error) {
      setFormError("En uventet feil oppsto. Vennligst prøv igjen senere.");
    }
  };

  return (
    <section className="py-24" id="kontakt">
      <div className="border-image-button-gray p-12 flex flex-col md:flex-row text-left gap-y-4">
        <div className="w-full md:w-1/2 flex flex-col gap-y-6">
          <h2 className="text-1xl font-pixeled">KONTAKT OSS</h2>
          <p>
            Send oss en e-post om hva du ønsker så kommer vi tilbake til deg så
            fort som mulig.
          </p>

          {formError && <p className="text-red-500">{formError}</p>}
          {successMessage && <p className="text-green-500">{successMessage}</p>}

          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <label htmlFor="navn">Navn</label>
              <input
                id="navn"
                type="text"
                placeholder="Ola Nordmann"
                className="px-4 py-2 text-black"
                value={navn}
                onChange={(e) => setNavn(e.target.value)}
                aria-label="Skriv inn ditt navn"
                required
              ></input>
            </div>
            <div className="flex flex-col gap-y-2">
              <label htmlFor="e-post">E-post</label>
              <input
                id="e-post"
                type="email"
                placeholder="ola.nordmann@gmail.com"
                className="px-4 py-2 text-black"
                value={epost}
                onChange={(e) => setEpost(e.target.value)}
                aria-label="Skriv inn din e-postadresse"
                required
              ></input>
            </div>
            <div className="flex flex-col gap-y-2">
              <label htmlFor="tjeneste">Tjeneste</label>
              <select
                id="tjeneste"
                className="text-black px-4 py-2"
                value={tjeneste}
                onChange={(e) => setTjeneste(e.target.value)}
                aria-label="Velg tjenesten du ønsker"
                required
              >
                <option value="Skreddersydd PC" selected>
                  Skreddersydd PC
                </option>
                <option value="PC reparasjon">PC Reparasjon</option>
                <option value="Kjøp PC">Kjøp PC</option>
              </select>
            </div>
            <div className="flex flex-col gap-y-2">
              <label htmlFor="melding">Melding</label>
              <textarea
                id="melding"
                placeholder="Skriv meldingen din her..."
                className=" text-black px-4 py-2"
                rows="3"
                value={melding}
                onChange={(e) => setMelding(e.target.value)}
                aria-label="Skriv din melding her"
                required
              ></textarea>
            </div>
            <Button
              text="Send melding"
              type="secondary"
              classNames="text-white w-full font-bold text-xl"
              onClick={handleSendMelding}
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 pl-8 h-full my-auto">
          <img
            src="https://pc-service.no/assets/contact.png"
            alt="PC-Service spesialist hjelper en kunde med spørsmål"
            className="border-image-button-gray p-3 w-full max-w-[30rem] mx-auto"
            loading="lazy"
          />
        </div>
      </div>

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ContactPage",
          name: "Kontakt PC Service",
          description:
            "Ta kontakt med PC Service for skreddersydde PC-er, reparasjoner og mer.",
          email: "support@pc-service.no",
          telephone: "+47 950 48 706",
          image: "https://pc-service.no/assets/contact.png",
          address: {
            "@type": "PostalAddress",
            addressLocality: "Fredrikstad",
            addressCountry: "NO",
          },
        })}
      </script>
    </section>
  );
};

export default Contact;
