import { Cpu, Flame, Laptop, Newspaper } from "lucide-react";

export const benefits = [
  {
    id: 1,
    icon: Laptop,
    title: "PC-bygg og oppgraderinger",
    description: "Få ekspertråd om tilpassede PCer",
  },
  {
    id: 2,
    icon: Cpu,
    title: "Reparasjonstips",
    description: "Lær å vedlikeholde og optimalisere PCen din",
  },
  {
    id: 3,
    icon: Flame,
    title: "Ekslusive tilbud",
    description: "Få tidlig tilgang til rabatter på PCer og tilbehør",
  },
  {
    id: 4,
    icon: Newspaper,
    title: "Teknologinyheter",
    description: "Hold deg oppdatert på siste nytt og trender",
  },
];

export const reviews = [
  {
    id: 1,
    image: "https://randomuser.me/api/portraits/men/8.jpg",
    name: "Ole Kristiansen",
    review:
      "Utrolig god service! De hjalp meg med å bygge en skreddersydd gaming PC, og den fungerte helt perfekt. Anbefales på det sterkeste!",
    dato: "12-12-2024",
  },
  {
    id: 2,
    image: "https://randomuser.me/api/portraits/women/1.jpg",
    name: "Ida Larsen",
    review:
      "Jeg fikk rask og effektiv reperasjon av PCen min etter at den sluttet å starte. Veldig profesjonelle og hyggelige folk!",
    dato: "12-12-2024",
  },
  {
    id: 3,
    image: "https://randomuser.me/api/portraits/men/14.jpg",
    name: "Erik Johansen",
    review:
      "Kjøpte en ferdigbygget PC herfra, og den leverer fantastisk ytelse til prisen. Superfornøyd med både kvaliteten og servicen!",
    dato: "12-12-2024",
  },
];
