import { useState } from "react";
import About from "./components/About";
import Benefits from "./components/Benefits";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Reviews from "./components/Reviews";
import Services from "./components/Services";
import Slider from "./components/Slider";

function App() {
  const [tjeneste, setTjeneste] = useState("Skreddersydd PC");

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="min-h-screen w-full bg-dark text-white">
      <div className="container mx-auto p-6 sm:p-12">
        <Navbar scrollToSection={scrollToSection} />
        <Hero scrollToSection={scrollToSection} />
      </div>
      <Slider />
      <div className="container mx-auto p-6 sm:p-12">
        <Benefits scrollToSection={scrollToSection} />
        <Services scrollToSection={scrollToSection} setTjeneste={setTjeneste} />
        <About />
        <Reviews />
        <Contact tjeneste={tjeneste} setTjeneste={setTjeneste} />
      </div>
      <Footer />
    </div>
  );
}

export default App;
