import React, { useState } from "react";
import Button from "./Universal/Button";

const Footer = () => {
  const [email, setEmail] = useState("");

  const [formError, setFormError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const WEBHOOK_URL =
    "https://pellenilsen.app.n8n.cloud/webhook/91e2b11d-43d0-4e08-a89c-bc283ae06562";

  const handleSubscribe = async () => {
    setFormError("");
    setSuccessMessage("");

    if (!email) {
      setFormError("Vennligst fyll inn en gyldig e-postadresse.");
      return;
    }

    try {
      const response = await fetch(WEBHOOK_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        setFormError("Noe gikk galt under abonnement. Prøv igjen senere.");
        return;
      }

      setSuccessMessage("Takk for at du abonnerer! Sjekk e-posten din.");
      setEmail("");
    } catch (error) {
      setFormError("En uventet feil oppsto. Prøv igjen senere.");
    }
  };

  return (
    <footer className="w-full py-16 bg-footer text-center" id="nyhetsbrev">
      <section className="container mx-auto flex flex-col gap-4 md:flex-row px-4">
        <div className="w-full md:w-1/2 flex flex-col gap-y-4">
          <h2 className="text-2xl font-pixeled leading-[3rem]">
            LIGG I FORKANT - BLI MED I VÅRT PC FELLESSKAP!
          </h2>
          <p>
            Abonner nå for eksperttips, ekslusive tilbud og PC råd rett i
            innboksen din.
          </p>
        </div>
        <div className="w-full md:w-1/2 flex flex-col gap-y-4">
          {formError && <p className="text-red-800">{formError}</p>}
          {successMessage && <p className="text-black">{successMessage}</p>}

          <input
            type="email"
            placeholder="ola.nordmann@gmail.com"
            className="text-center px-4 py-4 text-black"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="secondary"
            text="Abonner nå"
            classNames="w-full text-white"
            onClick={handleSubscribe}
            ariaLabel="Abonner på vårt nyhetsbrev"
          />
        </div>
      </section>

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "SubscribeAction",
          name: "PC Service Nyhetsbrev",
          description:
            "Abonner for å få eksklusive tilbud og PC-råd rett i innboksen din.",
          recipient: {
            "@type": "Person",
            email: email,
          },
          publisher: {
            "@type": "Organization",
            name: "PC Service",
            url: "https://pc-service.no",
          },
        })}
      </script>
    </footer>
  );
};

export default Footer;
